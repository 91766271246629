.modal {
    z-index: 1056; // ngb v12 bug: https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
}

.modal-content {
    background-color: var(--bs-body-bg);
    color: var(--body-text-color);
}

.modal-title {
    word-break: break-all;
}
